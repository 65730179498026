import $axios from '@/utils/request'

// 获取补课视频的事件
export function getInteractiveConfigById(data) {
  const url = '/edu/courseUnit/getInteractiveConfigById'
  return $axios.fPost(url, data)
}
// 保存最后推送得任务
export function saveLastPush(data) {
  const url = '/edu/interactionClass/saveLastPush'
  return $axios.fPost(url, data)
}
//在线文档查看
export function previewFile (data) {
  const url = '/common/file/preview'
  return $axios.fPost(url, data)
}
// 获取任务题目列表（单任务多题目模式）
export function getListQuestionforTask(data) {
  const url = '/edu/task/item/getList'
  return $axios.fGet(url, data)
}

// 增加课节控制学生端的目录
export function getCourseUnitState(data) {
  const url = '/edu/lesson/getLessonState'
  return $axios.fGet(url, data)
}
// 获取当前排课的上课状态
export function getLessonFinishedStatus(data) {
  const url = '/edu/lesson/getLessonFinishedStatus'
  return $axios.fGet(url, data)
}
// 增加课节控制学生端的目录
export function courseUnitSwitch(data) {
  const url = '/edu/lesson/lessonSwitch'
  return $axios.fPost(url, data)
}
// 增加rtm日志
export function rtmDayLog(data) {
  const url = '/edu/interactionClass/insertInteractionLog'
  return $axios.fPost(url, data)
}
// 获取班级排课信息
export function getLessonInfoByClassId(data) {
    const url = '/edu/classlession/getLessonInfoByClassId'
    return $axios.fGet(url, data)
}
// 获取班级学员信息
export function getClassStudentInfoById(data) {
    const url = '/edu/class/getClassStudentInfoById'
    return $axios.fGet(url, data)
}

// 获取班级老师信息
export function getClassTeacherInfoById(data) {
    const url = '/edu/class/getClassTeacherInfoById'
    return $axios.fGet(url, data)
}

// 获取任务下提交的作品列表
export function scratchList(data) {
    const url = '/edu/task/scratchList'
    return $axios.fPost(url, data)
}

// 获取任务python提交记录列表
export function getSubmitList(data) {
    const url = '/edu/task/listPythonTaskCode'
    return $axios.fPost(url, data)
}

// 获取活动python提交记录列表
export function getActivitySubmitList(data) {
    const url = '/edu/activity/listSubmit'
    return $axios.fPost(url, data)
}

// 获取GOC代码列表
export function getGocList(data) {
    const url = '/edu/task/gocList'
    return $axios.fPost(url, data)
}

// 批阅python代码
export function reviewPythonTaskCode(data) {
    const url = '/edu/task/reviewPythonTaskCode'
    return $axios.fPost(url, data)
}

// 获取班级上课进度
export function getProgress(data) {
    const url = '/edu/class/getProgress'
    return $axios.fGet(url, data)
}

// 用户课程树结构
export function getCourseByUser(data) {
    const url = '/edu/course/getPreparationCourseListByUser'
    return $axios.fPost(url, data)
}

// 课节列表展示
export function courseUnitList(data) {
    const url = '/edu/courseUnit/list'
    return $axios.fPost(url, data)
}

// 课节详情展示
export function courseUnitInfo(data) {
    const url = '/edu/courseUnit/getInfo'
    return $axios.fGet(url, data)
}

// 课节详情展示
export function getCourseInfo(data) {
  const url = '/edu/course/getInfo'
  return $axios.fPost(url, data)
}

// 备课任务列表展示
export function taskList(data) {
    const url = '/edu/task/list'
    return $axios.fPost(url, data)
}

// 说课视频列表展示
export function listAttachment(data) {
    const url = '/edu/courseUnit/listAttachment'
    return $axios.fPost(url, data)
}

// 获取任务教案内容
export function taskprepareGetInfo(data) {
    const url = '/edu/taskprepare/getInfo'
    return $axios.fGet(url, data)
}

// 新增修改教案内容
export function taskprepareEdit(data) {
    const url = '/edu/taskprepare/edit'
    return $axios.fPost(url, data)
}

// 获取在教班级
export function getClassByTeacher(data) {
    const url = '/edu/class/getClassByTeacher'
    return $axios.fPost(url, data)
}

//获取永久播放URL
export function getVideoUrl(data) {
    const url = '/edu/video/getVideoUrl'
    return $axios.fGet(url, data)
}

//获取播放URL的id
export function getVideoInfo(data) {
    const url = '/edu/video/getInfo'
    return $axios.fPost(url, data)
}


// 老师排课列表
export function getLessonList(data) {
    const url = '/edu/teacherLesson/list'
    return $axios.fPost(url, data)
}


export function getAgoraToken(data) {
    const url = '/edu/live/getAgoraToken'
    return $axios.fGet(url, data)
}

//开始上课接口
export function attendLesson(data) {
    const url = '/edu/lesson/attendLesson'
    return $axios.fPost(url, data)
}

//结束上课接口
export function finishLesson(data) {
    const url = '/edu/lesson/finishLesson'
    return $axios.fPost(url, data)
}

//获取排课成员
export function getLessonStudentInfoByIdWithPage(data) {
    const url = '/edu/lesson/getLessonStudentInfoByIdWithPage'
    return $axios.fPost(url, data)
}

//获取题库任务的统计
export function getUserSumitLogByContest(data) {
    const url = '/edu/contest/getUserSumitLogByContest'
    return $axios.fPost(url, data)
}

//获取题库任务列表
export function getQuestionList(data) {
    const url = '/edu/preview/question/getQuestionList'
    return $axios.fGet(url, data)
}

//
export function submitSolutionCode (data) {
  const url = '/edu/preview/question/submitSolutionCode'
  return $axios.fPost(url, data)
}

// 获取最后一次提交代码
export function getLastSubmit (data) {
  const url = '/edu/preview/question/getLastSubmit'
  return $axios.fGet(url, data)
}


// 查询题库详情
export function getContextInfo (data) {
  const url = '/edu/questionlibrary/getContextInfo'
  return $axios.fGet(url, data)
}

// 我的提交记录
export function mySubmitHistory (data) {
  const url = '/edu/preview/question/getSubmitHistory'
  return $axios.fGet(url, data)
}

export function getQuestionById (data) {
  const url = '/edu/preview/question/getQuestionById'
  return $axios.fGet(url, data)
}


//获取题库任务的记录
export function getSumitLogByContest(data) {
    const url = '/edu/contest/getSumitLogByContest'
    return $axios.fPost(url, data)
}

//获取试卷的统计
export function listExamUserStatByLesson(data) {
    const url = '/edu/exam/listExamUserStatByLesson'
    return $axios.fPost(url, data)
}

//获取试卷的记录
export function listExamUserByLesson(data) {
    const url = '/edu/exam/listExamUserByLesson'
    return $axios.fPost(url, data)
}



//获取老师备课scrtach提交记录
export function taskScratchList(data) {
    const url = '/edu/prepare/task/taskScratchList'
    return $axios.fPost(url, data)
}

//获取老师备课python提交记录
export function listPythonTaskCode(data) {
    const url = '/edu/prepare/task/listPythonTaskCode'
    return $axios.fPost(url, data)
}

//提交老师备课python代码
export function submitPythonTask(data) {
    const url = '/edu/prepare/task/submitPythonTask'
    return $axios.fPost(url, data)
}

//提交老师备课goc代码
export function submitGoc(data) {
    const url = '/edu/prepare/task/submitGoc'
    return $axios.fPost(url, data)
}

//获取老师备课goc提交记录
export function gocList(data) {
    const url = '/edu/task/gocList'
    return $axios.fPost(url, data)
}

// 根据提交记录id查询提交详情
export function getSubmitHistoryItem(data) {
    const url = '/edu/preview/question/getSubmitHistoryItem'
    return $axios.fGet(url, data)
}

// 课程 -> 课节 -> 任务详细信息
export function getTaskInfo(data) {
    const url = '/edu/task/getInfo'
    return $axios.fPost(url, data)
}
export function uploadScratch(data) {
    const url = '/meredu/task/uploadScratch'
    return $axios.fPost(url, data)
}
export function getListByUser(data) {
    const url = '/edu/course/creation/getListByUser'
    return $axios.fPost(url, data)
}
export function getInformation(data) {
    const url = '/edu/creation/getInformation'
    return $axios.fGet(url, data)
}

// /student/course/creation/saveCreation
export function saveCreation(data) {
    const url = '/edu/course/creation/saveCreation'
    return $axios.fPost(url, data)
}

export function getUploadTaskInfo(data) {
    const url = '/edu/task/getUploadTaskInfo'
    return $axios.fGet(url, data)
}

//做题相关接口
export function setTempAnswer(data) {
    const url = '/edu/exam/setTempAnswer'
    return $axios.fPost(url, data)
}

export function getInfo(data) {
    const url = '/edu/exam/getInfo'
    return $axios.fPost(url, data)
}

export function start(data) {
    const url = '/edu/exam/start'
    return $axios.fPost(url, data)
}

export function submit(data) {
    const url = '/edu/exam/submit'
    return $axios.fPost(url, data)
}

export function finish(data) {
    const url = '/edu/prepare/task/finish'
    return $axios.fPost(url, data)
}


export function getTempAnswer(data) {
    const url = '/edu/exam/getTempAnswer'
    return $axios.fGet(url, data)
}

// 获取服务器当前时间
export function serverTime(data) {
    const url = '/edu/other/now'
    return $axios.fGet(url, data)
}


// 模板
//获取模板
export function getSrcTplInfo(data) {
    const url = '/edu/srcTpl/info'
    return $axios.fGet(url, data)
  }
