<template>
  <el-row class="table">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的课表" name="first">
        <myLessonList  ref="myLessonList"></myLessonList>
      </el-tab-pane>
<!--      <el-tab-pane label="试听列表" name="three">-->
<!--        <tryLessonList  ref="tryLessonList"></tryLessonList>-->
<!--      </el-tab-pane>-->
      <el-tab-pane label="在教班级" name="second">
        <teachClass  ref="teachnowClass"></teachClass>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
  import teachClass from '@/viewsteacher/teacher/teachClass'
  import myLessonList from '@/viewsteacher/teacher/myLessonList'
  // import tryLessonList from '@/viewsteacher/components/try-lesson/myLessonList'

  export default {
    data() {
      return {
        activeName: 'first',
      };
    },
    components: {
      teachClass,
      myLessonList,
      // tryLessonList
    },
    methods:{
      handleClick(tab, event) {
        if(this.activeName === 'first' ) {
          this.$refs.myLessonList.getTeacherLessonList()
        }else if(this.activeName === 'second') {
         this.$nextTick(()=> {
           this.$refs.teachnowClass.getClassByTeacher()
         })
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/style/table.scss';
</style>
