<template>
  <!-- <div style="margin: 12px 24px"> -->
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="12">
          <el-col :xl="6" :lg="7" :md="24">
            <el-form-item label="上课日期：">
              <date-picker
                ref="dataPicker"
                :dateTime="dateTime"
                @updateView="datetTimeGet"
                class="w100"
              ></date-picker>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="5" :md="24">
            <el-form-item label="班级名称：">
              <el-input
                v-model="queryParam.className"
                placeholder="请输入"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <template>
            <el-col :xl="6" :lg="5" :md="24">
              <el-form-item label="上课状态：">
                <el-select
                  v-model="queryParam.lessonState"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lessonStateArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore="false"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex">
        <span>排课数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
          style="margin-top: 15px"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '班级名称'">
                <span v-if="scope.row.classInfo !== null">{{
                  scope.row.classInfo.name
                }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '上课时间'">
                <div>
                  <el-tag
                    ><span>{{
                      scope.row.start_time.substring(0, 10) | weekDay
                    }}</span></el-tag
                  >
                  <span>{{ scope.row.start_time | formatDateStart }}</span>
                  <span>{{ scope.row.end_time | formatDateEnd }}</span>
                </div>
              </template>
              <template v-else-if="item.label === '上课学员'">
                <el-popover
                  v-if="scope.row.lessonStudentList !== null"
                  placement="right"
                  width="350"
                  style="height: 400px;overflow-y: scroll"
                  trigger="click">
                  <el-table :data="scope.row.lessonStudentList">
                    <el-table-column property="name" label="姓名" align="center"></el-table-column>
                    <el-table-column width="160"  property="loginName" label="登录名" align="center"></el-table-column>
                    <el-table-column label="出勤状态" align="center">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.isAttend === '是'" type="success">已出勤</el-tag>
                        <el-tag v-else type="danger">未出勤</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text" style="text-decoration:underline">{{scope.row.lessonStudentList.length}}</el-button>
                </el-popover>
                <span v-else>0</span>
              </template>
              <template v-else-if="item.label === '教师名称'">
                <span>
                  {{scope.row.teacherInfo.name}}
                </span>
              </template>
              <template v-else-if="item.label === '课节名称'">
                <span
                  v-for="(item, index) in scope.row.courseUnitTitleList"
                  :key="index"
                >
                  <span>
                    {{ item
                    }}<span
                      v-if="index + 1 !== scope.row.courseUnitTitleList.length"
                      >、</span
                    >
                  </span>
                </span>
              </template>
              <template v-else-if="item.label === '上课老师'">
                <template
                  v-if="
                    scope.row.lessonTeacherList &&
                    scope.row.lessonTeacherList.length !== 0
                  "
                >
                  <el-row
                    v-for="(item, key) in scope.row.lessonTeacherList"
                    :key="key + 'zjName'"
                  >
                    <el-row
                      v-if="item.type === '主教' && Boolean(item.teacher)"
                      class="zjName mb-10"
                      >{{ item.teacher.name }}</el-row
                    >
                  </el-row>
                </template>
              </template>
              <template v-else-if="item.label === '类型'">

                <el-tag :type="scope.row.type === '常规' ? 'success' : scope.row.type === '补课' ? 'danger' : 'warning'">
                  {{ scope.row.type }}
                </el-tag>
              </template>
              <template v-else-if="item.label === '课次'">
                第{{ scope.row.courseUnitList[0].sort }}课
              </template>
              <template v-else-if="item.label === '状态'">
                <el-tag>
                  <span
                    class="span-state"
                    :style="
                      'backgroundColor' +
                      ':' +
                      getLessonFinishState(scope.row.finished_status)
                    "
                  ></span>
                  <span v-text="scope.row.finished_status"></span>
                </el-tag>
              </template>
              <template v-else-if="item.label === '排课操作'">
                <el-button
                  v-if="
                    scope.row.courseUnitList &&
                    scope.row.courseUnitList[0] &&
                    scope.row.courseUnitList[0].teachOnOff == 'Y'
                  "
                  type="text"
                  @click="gotoGiveLesson(scope.row, scope.$index)"
                >
                  去上课
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right; margin-right: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import getStateColor from "@/mixins/getStateColor";
import datePicker from "@/components/date-picker";
import { formatDateYMD } from "@/utils/formatDate";
import { getLessonList,getCourseInfo } from "@/apiteacher/teacher";
import { formatDate } from "@/utils/formatDate";

// 默认展示列
const baseColSetting = [
  {
    label: "班级名称",
    prop: "productName",
    state: true,
    align: "center",
    width: "160",
  },
  {
    label: "上课时间",
    state: true,
    align: "center",
    sortable: true,
    prop: "start_time",
    width: "235",
  },
  {
    label: "上课学员",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "教师名称",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课节名称",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "课次",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "类型",
    state: true,
    prop: "type",
    align: "center",
    width: "100",
  },
  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "排课操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  components: { TextButton, SearchQuery, datePicker },
  props: {
    type: {
      default: "",
      type: String,
      require: true,
    },
    schoolArray: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },
  mixins: [getStateColor],
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  data() {
    return {
      addlessonData: {},
      tableKey: 1,
      // 查询参数
      queryParam: { isFinish: false },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      lessonStateArray: [
        {
          value: "未上课",
          label: "未上课",
        },
        {
          value: "已上课",
          label: "已上课",
        },
        {
          value: "正在上课",
          label: "正在上课",
        },
      ],
      classIsAsc: false, // 默认为升序
      minStartTime: "",
      maxStartTime: "",
      dialogStu: false,
      lessonData: {},
      lessonDialog: false,
      lessonId: "",
      classId: "",
      courseId: "",
      schooltime: "",
      className: "",
      product_id: "",
      lessonStudentDialog: false,
      dataList: [],
      chooseDate: [],
      classInfo: {},
      lesson_id: "",
      class_id: "",
      baseInfo: {
          ci: null,//课程id
          cn: null,//课程名称
          ui: null,//课节id
          un: null,//课节名称
        }
    };
  },
  created(){
    const end = new Date();
    const start = new Date();
    const weekday = start.getDay();
    start.setTime(start.getTime()-3600*1000*24*(weekday -1));
    end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
    this.queryParam.time = [formatDateYMD(start),formatDateYMD(end)];
    this.dateTime = [formatDateYMD(start),formatDateYMD(end)];
    this.minStartTime = this.dateTime[0];
    this.maxStartTime = this.dateTime[1];
    this.getTeacherLessonList();
  },
  methods: {
    datetTimeGet(data) {
      if (data === null) {
        this.minStartTime = "";
        this.maxStartTime = "";
      } else {
        this.minStartTime = data[0];
        this.maxStartTime = data[1];
      }
    },

    search() {
      this.pagination.currentPage = 1;
      this.getTeacherLessonList();
    },

    // 获取老师排课的班级
    async getTeacherLessonList() {
      const res = await getLessonList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        isDesc:this.classIsAsc,
        campusIdSet: this.queryParam.campusIdSet,
        className: this.queryParam.className,
        type: this.queryParam.type,
        teacherId: this.queryParam.eduName,
        finishedStatus: this.queryParam.lessonState,
        minStartTime: this.minStartTime ? this.minStartTime + " 00:00:00" : "",
        maxStartTime: this.maxStartTime ? this.maxStartTime + " 23:59:59" : "",
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },

    // 班级列表排序
    tableSortAll(column) {
      switch (column.order) {
        case "ascending":
          this.classIsAsc = false;
          break;
        case "descending":
          this.classIsAsc = true;
      }
      this.getTeacherLessonList();
    },

    updateView(data) {},

    // 清空选项
    clearQuery() {
      this.queryParam = {};
      this.$refs.dataPicker.time = [];
      this.maxStartTime = "";
      this.minStartTime = "";
      this.staffList = [];
    },

    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getTeacherLessonList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTeacherLessonList();
    },

    // 设置标题
    setTitle(ele) {
      const start = formatDate(new Date(ele.start_time), "hh:mm");
      const end = formatDate(new Date(ele.end_time), "hh:mm");
      let setTitle = "";
      if (ele.classInfo !== null) {
        setTitle = `${start}-${end} ${ele.classInfo.name}`;
      } else {
        setTitle = `${start}-${end}` + "试听课";
      }
      return setTitle;
    },

    // 去上课
    async gotoGiveLesson(row,index) {
      this.baseInfo = {
        ci: row.courseUnitList[0].courseId,
        cn: row.courseName,
        ui: row.courseUnitList[0].id,
        un: row.courseUnitList[0].title,//课节名称
      }
      const res = await getCourseInfo({
        id:this.baseInfo.ci,
      })

      this.$nextTick(()=> {
        let routeData = this.$router.resolve({
          path: "/teacher/giveLesson-detail",
          query: {
            courseUnitId: this.dataList[index].courseUnitIdList[0],
            courseTitle: this.dataList[index].courseUnitList[0].title,
            lessonId: this.dataList[index].id,
            courseType:res.body.type,
            classId: this.dataList[index].class_id,
            courseStatus: this.dataList[index].finished_status,
            ...this.baseInfo,
          },
        });
        window.open(routeData.href, "_blank");
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";
</style>
