<template>
  <el-col :md="(!advanced && 6) || 24" :sm="24" style="margin-bottom: 12px;">
    <span
      class="table-page-search-submitButtons"
      :style="
        (advanced && {
          float: 'right',
          marginRight: '30px',
          overflow: 'hidden',
        }) ||
          {}
      "
    >
      <el-button type="primary" @click="$emit('searchQuery')" size="small" v-text="queryText"
        ></el-button
      >
      <el-button style="margin-left: 8px" @click="clearQuery" size="small"
        >重 置</el-button
      >
      <a @click="toggleAdvanced" style="margin-left: 8px" v-if="showMore">
        {{ advanced ? '收起' : '展开' }}
        <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
      </a>
    </span>
  </el-col>
</template>

<script>
  export default {
    props: {
      showMore: {
        default: true,
        type: Boolean
      },
      queryText: {
        default: '查 询',
        type: String
      }
    },
    data() {
      return {
        // 高级搜索 展开/关闭
        advanced: false,
      }
    },
    methods: {
      toggleAdvanced() {
        this.advanced = !this.advanced
        this.$emit('updateView', this.advanced)
      },

      clearQuery() {
        this.$emit('clearQuery')
      },
    },
  }
</script>
